import algoliasearch from 'algoliasearch'
import { useEffect, useState } from 'react'
import { firebase, firestore, storage } from './firebase'
import {
  Button,
  Container,
  Flex,
  Text,
  Image,
  Link,
  Stack,
  Alert,
  AlertIcon,
  Box,
  Heading
} from '@chakra-ui/react'
import {
  InputGroup,
  Spacer,
  InputRightElement,
  Input,
  Tooltip
} from '@chakra-ui/react'
import { List, ListItem, Divider } from '@chakra-ui/react'
import { Grid, GridItem } from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react'
import {
  ExternalLinkIcon,
  Search2Icon,
  EditIcon,
  CalendarIcon,
  ViewIcon,
  AttachmentIcon
} from '@chakra-ui/icons'
import { BeatLoader } from 'react-spinners'
import './App.css'

const PAGE_COUNT = 20

function App(props) {
  const [articles, setArticles] = useState([])
  const [content, setContent] = useState({ show: false })
  const [fetching, setFetching] = useState(false)
  const [queryString, setQueryString] = useState('')
  const [quotaError, setQuotaError] = useState(false)
  const [isSearchResult, setIsSearchResult] = useState(false)
  const [lastVisible, setLastVisible] = useState(null)

  useEffect(() => {
    fetchArticles(true)
  }, [])

  const home = () => {
    fetchArticles(true)
  }

  const onMore = () => {
    fetchMoreArticles()
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    searchArticles(queryString)
  }

  const fetchArticles = async (reset) => {
    setFetching(true)
    setQueryString('')

    if (reset) {
      setLastVisible(null)
      setIsSearchResult(false)
    }

    try {
      const snapshot = await firestore
        .collection('pds')
        .orderBy('index', 'desc')
        .limit(PAGE_COUNT)
        .get()

      setLastVisible(snapshot.docs[snapshot.docs.length - 1])

      setArticles(
        snapshot.docs.map((doc) => ({ ...doc.data(), docId: doc.id }))
      )
      setFetching(false)
    } catch (error) {
      if (error.message === 'Quota exceeded.') {
        setFetching(false)
        setQuotaError(true)
        setQueryString('')
      }
    }
  }

  const fetchMoreArticles = async () => {
    setFetching(true)
    setQueryString('')

    try {
      const snapshot = await firestore
        .collection('pds')
        .orderBy('index', 'desc')
        .startAfter(lastVisible)
        .limit(PAGE_COUNT)
        .get()

      setLastVisible(snapshot.docs[snapshot.docs.length - 1])

      setArticles([
        ...articles,
        ...snapshot.docs.map((doc) => ({ ...doc.data(), docId: doc.id }))
      ])

      setFetching(false)
    } catch (error) {
      if (error.message === 'Quota exceeded.') {
        setFetching(false)
        setQuotaError(true)
        setQueryString('')
      }
    }
  }

  const searchArticles = async (queryString) => {
    setArticles([])
    setFetching(true)

    const ALGOLIA_ID = '281J0H82S2'
    const ALGOLIA_SEARCH_KEY = '5f19415f3c7bbe4c0c5ed2e7057fa2e1'

    const client = algoliasearch(ALGOLIA_ID, ALGOLIA_SEARCH_KEY)
    const index = client.initIndex('gmapds')

    try {
      const result = await index.search(queryString, { hitsPerPage: 512 })

      // Convert timestamp
      const hits = result.hits.map((article) => {
        const _seconds = article.timestamp._seconds
        article.timestamp = new firebase.firestore.Timestamp(_seconds, 0)
        article.docId = article.objectID
        return article
      })

      setArticles(hits)
    } catch (error) {
      setArticles([])
    }

    setFetching(false)
    setIsSearchResult(true)
  }

  const showContent = async (index) => {
    const article = articles[index]
    const fileUrl = await storage.ref().child(article.filename).getDownloadURL()

    const shortFilename = /[0-9]+_(.+)/.exec(article.filename)

    setContent({
      show: true,
      index: article.index,
      title: article.title,
      name: article.name,
      id: article.id,
      dateTime:
        article.timestamp.toDate().toISOString().substring(0, 10) +
        ' ' +
        article.timestamp.toDate().toISOString().substring(11, 16),
      read: article.read,
      filename: shortFilename[1],
      fileUrl,
      description: article.description
    })
  }

  const hideContent = () => {
    setContent({})
  }

  return (
    <Stack>
      <Flex
        py={2}
        px={4}
        mb={2}
        alignItems="center"
        borderBottom="1px"
        borderColor="inherit"
      >
        <Image
          src="/logo.png"
          alt="HOME"
          w="30px"
          h="30px"
          onClick={() => home()}
          style={{ cursor: 'pointer' }}
        />
        <Text
          d={{ base: 'none', md: 'flex' }}
          ml={2}
          onClick={() => home()}
          style={{ cursor: 'pointer' }}
        >
          HiTEL 게제동(GMA) 공개자료실
        </Text>
        <Link href="https://cafe.naver.com/olddos" isExternal mx="2">
          <Button variant="outline">
            도스박물관 카페
            <ExternalLinkIcon mx="2px" />
          </Button>
        </Link>
        <Spacer />
        <Tooltip label="제목, 본문, 이름, 아이디">
          <form onSubmit={(event) => handleSubmit(event)}>
            <InputGroup w={{ base: '9.2rem', md: '16rem' }}>
              <Input
                value={queryString}
                onChange={(event) => setQueryString(event.target.value)}
              />
              <InputRightElement children={<Search2Icon />} />
            </InputGroup>
          </form>
        </Tooltip>
      </Flex>
      <Flex justifyContent="center">
        <Container px="2" maxW="1000px" alignSelf="center" centerContent>
          <Alert status="info" borderRadius="lg">
            <AlertIcon />
            백업자료를 공유해주신 게임개발자연대 오영욱 이사님께 감사드립니다.
          </Alert>
          {quotaError && (
            <Alert status="error" borderRadius="lg" my={2}>
              <AlertIcon />
              네트워크 일일 사용량이 초과되었습니다. 24시간 후에 재시도
              부탁드립니다.
            </Alert>
          )}
          <Box
            w="100%"
            py="4"
            mt="4"
            border="1px"
            borderColor="inherit"
            borderRadius="lg"
          >
            <List w="100%">
              <ListItem px="4">
                <Grid
                  templateColumns="repeat(12, 1fr)"
                  mb="2"
                  d={{ base: 'none', md: 'grid' }}
                >
                  <GridItem
                    mx="1"
                    colSpan="1"
                    fontWeight="bold"
                    color="gray.500"
                  >
                    번호
                  </GridItem>
                  <GridItem
                    mx="1"
                    colSpan="5"
                    fontWeight="bold"
                    color="gray.500"
                  >
                    제목
                  </GridItem>
                  <GridItem
                    mx="1"
                    colSpan="3"
                    fontWeight="bold"
                    color="gray.500"
                  >
                    작성자(ID)
                  </GridItem>
                  <GridItem
                    mx="1"
                    colSpan="2"
                    fontWeight="bold"
                    color="gray.500"
                  >
                    작성일
                  </GridItem>
                  <GridItem
                    mx="1"
                    colSpan="1"
                    fontWeight="bold"
                    color="gray.500"
                    textAlign="right"
                  >
                    조회수
                  </GridItem>
                </Grid>
                <Grid
                  templateColumns="repeat(1, 1fr)"
                  mb="2"
                  d={{ base: 'grid', md: 'none' }}
                >
                  <GridItem
                    mx="1"
                    colSpan="1"
                    fontWeight="bold"
                    color="gray.500"
                  >
                    제목 / 작성자(ID) / 작성일
                  </GridItem>
                </Grid>
              </ListItem>
              <Divider />
              {articles.map((article, index) => (
                <div key={article.docId}>
                  <ListItem
                    className="article"
                    onClick={() => showContent(index)}
                    px="4"
                  >
                    <Grid
                      d={{ base: 'none', md: 'grid' }}
                      templateColumns="repeat(12, 1fr)"
                      py={3}
                    >
                      <GridItem mx="1" colSpan="1">
                        {article.index}
                      </GridItem>
                      <GridItem mx="1" colSpan="5">
                        {article.title}
                      </GridItem>
                      <GridItem
                        mx="1"
                        colSpan="3"
                      >{`${article.name} (${article.id})`}</GridItem>
                      <GridItem mx="1" colSpan="2">
                        {article.timestamp
                          .toDate()
                          .toISOString()
                          .substring(0, 10)}
                      </GridItem>
                      <GridItem mx="1" colSpan="1" textAlign="right">
                        {article.read}
                      </GridItem>
                    </Grid>
                    <Stack d={{ base: 'grid', md: 'none' }} py={3}>
                      <Heading size="sm" whiteSpace="nowrap" overflow="hidden">
                        {article.title}
                      </Heading>
                      <Text mt="0 !important">
                        <EditIcon color="gray.500" w="12px" h="12px" mr="2" />
                        <small>{`${article.name} (${article.id})`}</small>
                      </Text>
                      <Text mt="0 !important">
                        <CalendarIcon
                          color="gray.500"
                          w="12px"
                          h="12px"
                          mr="2"
                        />
                        <small>
                          {article.timestamp
                            .toDate()
                            .toISOString()
                            .substring(0, 10)}
                        </small>
                        <ViewIcon
                          color="gray.500"
                          w="12px"
                          h="12px"
                          ml="4"
                          mr="2"
                        />
                        <small>{article.read}</small>
                      </Text>
                    </Stack>
                  </ListItem>
                  <Divider />
                </div>
              ))}
              <ListItem m="3">
                <Button
                  w="100%"
                  isLoading={fetching}
                  disabled={isSearchResult}
                  spinner={<BeatLoader size="8px" color="white" />}
                  onClick={() => onMore()}
                >
                  더 보기
                </Button>
              </ListItem>
            </List>
          </Box>
          <Modal
            size="xl"
            isOpen={content.show}
            onClose={() => setContent({ show: false })}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader mr="4">{content.title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text mb="1">
                  <EditIcon color="gray.500" mr="2" />
                  {content.name} ({content.id})
                </Text>
                <Text mb="1">
                  <CalendarIcon color="gray.500" mr="2" />
                  {content.dateTime}
                  <ViewIcon color="gray.500" ml="4" mr="2" />
                  {content.read}
                </Text>
                <Text mb="1">
                  <AttachmentIcon color="gray.500" mr="2" />
                  <Link href={content.fileUrl}>{content.filename}</Link>
                </Text>
                <Divider my="4" />
                <Text>
                  {content.description &&
                    content.description.split('\n').map((line, index) => (
                      <span key={content.description + index}>
                        {line}
                        <br />
                      </span>
                    ))}
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => hideContent()}>닫기</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Container>
      </Flex>
      <Box mt="3" mb="8" textAlign="center">
        ©{' '}
        <Link href="mailto:gcjjyy@icloud.com" isExternal>
          gcjjyy@icloud.com
        </Link>
      </Box>
    </Stack>
  )
}

/*
show: true,
      index: article.index,
      title: article.title,
      name: article.name,
      id: article.id,
      dateTime: (article.timestamp.toDate().toISOString().substring(0, 10) + ' ' +
        article.timestamp.toDate().toISOString().substring(11, 16)),
      read: article.read,
      filename: shortFilename[1],
      fileUrl,
      description: article.description
*/

export default App

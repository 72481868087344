import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyAVWck44SZzFNOyCGxgKIrzdiiAMWon_Hg',
  authDomain: 'gmapds-1dcb9.firebaseapp.com',
  databaseURL: 'https://gmapds-1dcb9.firebaseio.com',
  projectId: 'gmapds-1dcb9',
  storageBucket: 'gmapds-1dcb9.appspot.com',
  messagingSenderId: '44523721443',
  appId: '1:44523721443:web:5d9b846f24a49c98316bd6'
}

firebase.initializeApp(firebaseConfig)

const firestore = firebase.firestore()
const storage = firebase.storage()

export { firebase, firestore, storage }
